<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- nome -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          label="Nome do Cargo"
          ref="nome"
          placeholder="Ex.: Administrativo"
          required
          outlined
          dense
          v-model="cargo.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('avd-cargo.nome')"
          :error="errors.collect('avd-cargo.nome').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-cargo"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Filial"
          :items="filiais"
          item-text="nome"
          item-value="id"
          placeholder="Selecione uma filial"
          outlined
          dense
          required
          v-model="cargo.id_filial"
          :loading="loadings.loadingFilial"
          no-data-text="Nenhuma filial selecionada"
        ></v-select>
      </v-col>
      <!-- Cliente -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Cliente"
          :items="clientes"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um cliente"
          outlined
          dense
          required
          v-model="cargo.id_cliente"
          :loading="loadings.loadingCliente"
          no-data-text="Nenhum cliente selecionado"
        ></v-select>
      </v-col>
      <!-- AvdSetor -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Setor"
          :items="avdSetor"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um setor"
          outlined
          dense
          required
          v-model="cargo.id_setor"
          :loading="loadings.loadingAvdSetor"
          data-vv-as="setor"
          persistent-hint
          :hint="errors.first('avd-cargo.setor')"
          :error="errors.collect('avd-cargo.setor').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-cargo"
          id="setor"
          name="setor"
          no-data-text="Nenhum setor selecionado"
        ></v-select>
      </v-col>
      <!-- Salário -->
      <v-col cols="12">
        <v-text-field
          label="Salário"
          ref="salario"
          placeholder="Ex.: 1.600,00"
          required
          outlined
          dense
          v-model="cargo.salario"
          data-vv-as="salário"
          persistent-hint
          :hint="errors.first('avd-cargo.salario')"
          :error="errors.collect('avd-cargo.salario').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur"
          data-vv-scope="avd-cargo"
          id="salario"
          name="salario"
          prefix="R$"
          v-mask="[
            '###,##',
            '#.###,##',
            '##.###,##',
            '###.###,##',
            '#.###.###,##',
          ]"
        ></v-text-field>
      </v-col>
      <!-- descricao -->
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          label="Descrição"
          ref="descricao"
          placeholder="Ex.: Uma parte essencial da empresa."
          outlined
          dense
          v-model="cargo.descricao"
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('avd-cargo.descricao')"
          :error="errors.collect('avd-cargo.descricao').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-cargo"
          id="descricao"
          name="descricao"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FormCargos",
  inject: ["$validator"],
  async created() {
    const dataFilial = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataAvdSetor = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataCliente = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    await this.$store.dispatch("filiais/findByColumns", dataFilial);
    this.loadings.loadingFilial = false;

    await this.$store.dispatch("clientes/getAllDatabyColumns", dataCliente);
    this.loadings.loadingCliente = false;

    await this.$store.dispatch("avdSetores/findByColumns", dataAvdSetor);
    this.loadings.loadingAvdSetor = false;
  },
  data() {
    return {
      loadings: {
        loadingFilial: true,
        loadingAvdSetor: true,
        loadingCliente: true,
      },
    };
  },
  computed: {
    cargo: {
      get() {
        return this.$store.getters["avdCargos/getCargo"];
      },
    },
    avdSetor: {
      get() {
        return this.$store.getters["avdSetores/getSetores"];
      },
    },
    filiais: {
      get() {
        const arrayFilial = this.$store.getters["filiais/getFiliais"];

        arrayFilial.unshift({ id: null, nome: "Nenhuma filial selecionada" });

        return arrayFilial;
      },
    },
    clientes: {
      get() {
        const arrayCliente = this.$store.getters["clientes/getClientes"];

        arrayCliente.unshift({ id: null, nome: "Nenhum cliente selecionado" });

        return arrayCliente;
      },
    },
  },
};
</script>

<style></style>
