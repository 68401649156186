<template>
  <section name="cargo">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Cargos"
          buttonAddItemText="Novo Cargo"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :loadingButtonSave="loadings.loadingButtonSave"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="cargos"
              :loading="loadings.loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Delete -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  true-value="A"
                  false-value="I"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.salario`]="{ item }">
                <span>R$ {{ item.salario | doubleToCurrency() }}</span>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <FormCargos />
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.salario`]="{ item }">
        <span>R$ {{ item.salario | doubleToCurrency() }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">
          <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import FormCargos from "./components/cargos/FormCargos.vue";
import { convertCurrencyToDouble } from "@/helpers/index.js";

export default {
  name: "cargos-Component",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    FormCargos,
  },
  data() {
    return {
      nameForm: "Novo Cargo",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      SelectItensStatus: [
        { text: "Ativo", value: "A" },
        { text: "Inativo", value: "I" },
      ],
      searchTextField: "",
      dialog: false,
      loadings: {
        loadingButtonSave: false,
        loadingTable: false,
      },
      headers: [
        { text: "Nome", align: "start", value: "nome" },
        { text: "Salário", value: "salario" },
        { text: "Status", value: "status" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      headerModalLateral: [
        { text: "Nome", value: "nome" },
        { text: "Filial", value: "filial.nome" },
        { text: "Setor", value: "setor.nome" },
        { text: "Cliente", value: "cliente.nome" },
        { text: "Salário", value: "salario" },
        { text: "Descrição", value: "descricao" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
        { text: "Status", value: "status" },
      ],

      // Modal delete
      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },

  async created() {
    this.loadings.loadingTable = true;

    await this.$store.dispatch("avdCargos/initialize");

    this.loadings.loadingTable = false;
  },

  computed: {
    cargo: {
      get() {
        return this.$store.getters["avdCargos/getCargo"];
      },
      set(value) {
        this.$store.dispatch("avdCargos/setCargo", value);
      },
    },
    cargos: {
      get() {
        return this.$store.getters["avdCargos/getCargos"];
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("avd-cargo.*").then((result) => {
        if (result) {
          const isEdit = this.cargo.id != undefined ? 1 : -1;
          this.loadings.loadingButtonSave = true;

          this.loadings.loadingTable = true;

          let url =
            isEdit === -1 ? "/avd-cargo" : `/avd-cargo/${this.cargo.uuid}`;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Cargo cadastrado com sucesso."
              : "Cargo atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar cargo."
              : "Erro ao atualizar cargo.";

          let cargoItem = this.cargo;
          cargoItem._method = method;
          cargoItem.url = url;
          cargoItem.salario = convertCurrencyToDouble(this.cargo.salario); // Converte a mascara de Reais em Double

          this.$store
            .dispatch("avdCargos/save", cargoItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.cargos.push(response);
                } else {
                  const index = this.cargos.findIndex(
                    (el) => el.id == cargoItem.id
                  );

                  Object.assign(this.cargos[index], cargoItem);
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.loadings.loadingButtonSave = false;
              this.loadings.loadingTable = false;
              this.fechar();
            });
        }
      });
    },
    editar(item) {
      this.loadings.loadingTable = true;

      this.$store
        .dispatch("avdCargos/findByUuid", item.uuid)
        .then((response) => {
          this.cargo = response;
          this.nameForm = "Editar cargo: " + this.cargo.nome;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do cargo selecionado.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.nameForm = "Novo cargo";
      this.cargo = {
        id_empresa: null,
        id_filial: null,
        id_cliente: null,
        id_setor: null,
        nome: "",
        descricao: "",
        salario: null,
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.cargos.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("avdCargos/remove", this.item.uuid)
          .then((response) => {
            if (response === true) {
              this.cargos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Cargo removido com sucesso.",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o cargo. Por favor, tente novamente mais tarde!",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
            this.item = [];
          });
      }
    },
    mudarStatus(item) {
      this.loadings.loadingTable = true;

      this.indexEdicao = this.cargos.findIndex((el) => el.id == item.id);

      let cargoItem = {
        id: item.id,
        uuid: item.uuid,
        status: item.status,
        url: `/avd-cargo/${item.uuid}`,
        _method: "PUT",
      };

      this.$store
        .dispatch("avdCargos/save", cargoItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.cargos[this.indexEdicao].status = cargoItem.status;

            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Status atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar o status.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    toggleModalLateral(item) {
      if (!this.modalLateral.show) {
        this.loadings.loadingTable = true;

        this.$store
          .dispatch("avdCargos/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = item ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do cargo selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadings.loadingTable = false;
          });
      } else {
        this.modalLateral.title = "";
        this.modalLateral.item = [];
        this.modalLateral.show = false;
      }
    },
  },
};
</script>

<style scoped></style>
